.hero_button {
  margin: 2rem 0 1rem 0 !important ;
  border-radius: 26px !important;
  color: #006838 !important;
  font-weight: 700 !important;
  border-color: #006838 !important;
  max-width: max-content !important;
  transition: 1s all ease-in-out;
}
.hero_button:hover {
  color: #fff !important;
  background: linear-gradient(
    180deg,
    var(--first-linear-green) 0%,
    var(--second-linear-green) 100%
  ) !important;
  border: none !important;
}

.cardContainer {
  position: relative;
  transform-style: preserve-3d;

  background: #fff;
  /* box-shadow: 0px 36px 25px -28px rgba(0, 0, 0, 0.13); */
  transition: 0.5s ease;
  animation: dropdownback 0.5s;
  display: flex;
}

.cardContainer .face {
  position: relative;
  flex: 1;
}

.card3Image {
  position: absolute;
  top: -61px;
  background-color: #f8f9fa;
  border-radius: 50%;
  padding: 0.5em;
  overflow: hidden;
}
.card3Image img {
  border-radius: 50%;
  background-color: transparent;
  object-fit: contain;
  overflow: hidden;
}

.back {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: 0.5s all ease;
  opacity: 1;
}

.cursorEffect {
  cursor: pointer;
}

.cursorEffect:hover {
  color: #006838;
}
