/* global style  */
* {
  margin: 0;
  padding: 0;
  font-family: "Janna";
}
body,
div,
a,
p,
h1,
h2,
h3,
h4,
h5,
ul,
li,
ol,
span,
td,
th,
button,
option {
  font-family: "Janna" !important;
}
html {
  direction: rtl;
}
:root {
  --light-blue: #edf7ff;
  --mid-blue: #d7e7fe;
  --dark-blue: #112c5b;
  --blue: #1c56a4;
  --yellow: #ffb83a;
  /* #6FE286 */
  /* #2B7239 */
  --blue2: #6aa7fd;
  --white: #f5f5f5;
  --green: #016938;
  --dark-green: #006838;
  --first-linear-green: #6fe286;
  --med-linear-green: #4daa60;
  --second-linear-green: #2b7239;

  /* not used */
  /* --lite-green: #45a358;
  --lite-green: #2C6838; */

  /* second them colors */
  /* --first-linear-green: #54B4E4;
  --med-linear-green: #54b4e4e0;
  --second-linear-green: #54b4e4b6; */
  --dark-grey: #393939;
  --grey: #9e9e9e;
  --red: #d1202f;
  --field-BG: #edeff0;
}
@media (max-width: 550px) {
  .mb-2half {
    flex-direction: column;
  }
}
.text-500 {
  font-weight: 500;
}
.text-600 {
  font-weight: 600;
}
.text-blue {
  color: var(--dark-blue);
}
.text-red {
  color: var(--red);
}
.text-green {
  color: var(--green);
}
.border-green {
  border: 1px solid var(--green);
  border-radius: 12px;
}
.error-message {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: right;
  margin-top: 0px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
.error-border {
  border: 0.1px solid rgba(209, 32, 47, 0.25) !important;
}
.error-border:focus {
  /* background-color: var(--red); */
  border-color: var(--red);
  outline: 0;
  border: 0.1px solid var(--red) !important;
  box-shadow: 0 0 0 0.25rem rgba(209, 32, 47, 0.25) !important;
}
.error-msg {
  font-size: 0.75rem;
  color: var(--red);
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.custome-tooltip {
  border: 1px soild #ededed;
  background-color: #ededed;
  padding: 1rem;
  color: red;
}
.form-check-input[type="radio"],
.form-check-input:checked[type="radio"] {
  padding: 0.2rem !important;
}
.form-check .form-check-input {
  float: left;
  margin-left: 0.5em !important;
}
::-webkit-scrollbar {
  width: 5px;
}
/* input new design */
.input-group .form-control {
  border-radius: 12px !important;
  background-color: var(--field-BG) !important;
  padding: 0.7rem !important;
  outline: 0;
  border: 0px solid var(--blue);
  box-shadow: 0 0 0 0 !important;
}

.date-container {
  background-color: var(--field-BG);
  border-radius: 12px;
}

/* scrollbar costomize */
::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: var(--dark-blue);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--dark-blue);
}
/* end scrollbar costomize */
.title {
  display: flex;
  justify-content: flex-start;
  padding-right: 0rem;
  padding-bottom: 2rem;
}
.title h2 {
  color: var(--dark-grey);
}
.icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.icons i {
  margin-left: 0.2rem;
  color: var(--dark-green);
}
@media (max-width: 768px) {
  .title {
    padding-right: 4rem;
  }
}

@media (max-width: 550px) {
  .title {
    padding-right: 1rem;
  }
}

.customCheckbox .Mui-checked {
  color: #fff !important;
}

.customRadioButtons .Mui-checked,
.customSingleCheckbox .Mui-checked {
  /* color: #439e56 !important; */
  color: var(--med-linear-green) !important;
}
.Mui-active,
.Mui-completed {
  /* color: #68d574 !important; */
  color: var(--first-linear-green) !important;
}

.MuiStepIcon-text {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 700 !important;
}
.MuiStepConnector-line {
  border-color: unset !important;
}
.react-international-phone-input-container {
  width: 60%;
  background: #d8d9da;
  padding: 0.3rem;
  border-radius: 12px;
  direction: ltr;
}
.react-international-phone-input-container .react-international-phone-input {
  border: none !important;
  background: #d8d9da !important;
  border-radius: 12px;
  outline: none;
  text-align: left !important;
}
.phoneErrorClass {
  border: 1px solid red;
}
.react-international-phone-country-selector-button {
  border: none !important;
  background: #d8d9da !important;
}
#phoneMaskInput {
  text-align: left !important;
}
.page-container {
  padding-left: 11rem;
  padding-right: 11rem;
}
@media (max-width: 1200px) {
  .page-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  .page-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 550px) {
  .page-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
/* toaster align right */
.Toastify__toast-container {
  direction: rtl;
  text-align: right;
}
.Toastify__toast-body {
  padding-right: 1rem;
}
.Toastify__toast--success {
  background: var(--green) !important;
  color: white;
}
@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 90vw;
    padding: 0;
    right: 0;
    margin: 0;
    direction: rtl;
    text-align: right;
  }
}

.view-container {
  min-height: 60vh;
}
@media (max-width: 1024px) {
  .view-container {
    min-height: 500px;
  }
}
.request-actions {
  display: flex;
  justify-content: center;
}
.request-page-section {
  margin: 2rem 0;
}
.no-elements {
  height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-elements p {
  font-size: 1.3rem;
  font-weight: 500;
}
.d-contents {
  display: contents !important;
}
