.backDrop1 {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5) !important;
  position: absolute;
}

.backDrop2 {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5) !important;
  position: absolute;
}

.Buisness {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.option1BgBuisness {
  background-image: url(../../../assets/images/Slider/Option1-Mo7tarifon.jpg);
}

.option2BgBuisness {
  background-image: url(../../../assets/images/Slider/Option2-mo7tarifon.webp);
}

.Consumer {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.option1BgConsumer {
  background-image: url(../../../assets/images/Slider/Option1-Consumer.jpg);
}

.option2BgConsumer {
  background-image: url(../../../assets/images/Slider/Option2-COnsumer.jpg);
}
.option3BgConsumer {
  background-image: url(../../../assets/images/Slider/Consumer.webp);
  background-position: top 8px right 65%;
  background-size: cover;
}

.hero_text_box {
  position: relative;
  z-index: 3;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, -100px, 0);
  transform: translate3d(0, -100px, 0);
  margin-top: 10rem;
}

.hero_text {
  font-size: 2.3rem !important;
  color: #fff;
  font-weight: 800 !important;
  line-height: 2;
  /* border-bottom: 3px solid #fff;
  padding-bottom: 0.8rem; */
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 150px, 0);
  transform: translate3d(0, 150px, 0);
  line-height: 1.8 !important;
}
.hero_text:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 3px;
  background-color: #fff;
}
.hero_sub_text {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  color: #fff;
  width: 50%;
  transition: all ease-in-out 0.5s;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0) scale(0.5);
  transform: translate3d(0, 20px, 0) scale(0.5);
}

.hero_button {
  border-radius: 26px !important;
  color: #fff !important;
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  border-color: #fff !important;
  max-width: max-content !important;
  -webkit-transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  -webkit-transform: translate3d(0, -25px, 0);
  transform: translate3d(0, -25px, 0);
  opacity: 0;
}

.hero_text_box:hover .hero_button {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
  background: linear-gradient(
    180deg,
    var(--first-linear-green) 0%,
    var(--second-linear-green) 100%
  ) !important;
  border-color: none !important;
  border: none !important;
  color: #fff;
}
.hero_text_box:hover .hero_sub_text {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
}
.hero_text_box:hover .hero_text {
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
}

.hero_text_box:hover {
  transition: all 0.5s ease;
}
.hero_text_box:hover .hero_button {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
}

.Consumer:hover > .backDrop2 {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.Buisness:hover > .backDrop1 {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

@media (max-width: 991px) {
  .option3BgConsumer {
    background-position: top 0px right 65%;
  }
}
@media (max-width: 768px) {
  .hero_text_box {
    margin-top: 12rem;
    text-align: center;
  }
  .hero_sub_text {
    width: 75%;
  }
}

@media (max-width: 500px) {
  .option2BgBuisness {
    margin-top: 6.5rem;
  }
  .hero_text {
    font-size: 1.5rem !important;
  }
}
@media (max-width: 374px) {
  .hero_text {
    font-size: 1.5rem !important;
  }
  .hero_text_box {
    margin-top: 12rem;
  }
}
