@font-face {
  font-family: "Janna";
  src: url("../fonts/Bahij\ Janna-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Janna";
  src: url("../fonts/Bahij\ Janna-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
