.title {
  color: #000 !important;
  text-align: center !important;
  font-family: Cairo !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 188.9% !important; /* 30.224px */
}

.value {
  color: #000 !important;
  text-align: center !important;
  font-family: Cairo !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 188.9% !important; /* 34.002px */
}

.resend {
  cursor: pointer !important;
  text-decoration-line: underline !important;
  color: #004496 !important;
}

.inputValue {
  color: #004496 !important;
  text-align: center !important;
  font-family: Cairo !important;
  font-size: 25px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 188.9% !important; /* 47.225px */
}

.resendBlock {
  pointer-events: unset !important;
  cursor: not-allowed !important;
  text-decoration-line: none !important;
}
