.root {
  display: flex;
  align-items: center;
}
.inputLabel {
  align-self: flex-start !important;
  min-width: 130px !important;
  white-space: nowrap !important;
  padding-left: 0.5rem !important;
  color: #000 !important;
  font-family: Cairo !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 188.9% !important; /* 34.002px */
}
.inputField input {
  text-align: left !important;
}
.requiredField::after {
  content: "*";
  color: #000;
}

.errorClass,
.errorClass::after {
  color: red !important;
}

.phoneMask {
  width: 60%;
  background: #d8d9da;
  padding: 0.7rem;
  border-radius: 12px;

  outline: none;
}

.lastStepPhone {
  min-width: 170px !important;
}
