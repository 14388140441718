.pageContainer {
  padding-top: 4rem;
  min-height: 85vh;
  display: flex !important;
  flex-direction: column !important;
}
.bg {
  background-image: url(../../assets/images/bg/registerBg.png);
  background-repeat: no-repeat;
  background-position: bottom right;
}
@media (max-width: 1024px) {
  .pageContainer {
    min-height: 550px;
  }
}
@media (max-width: 768px) {
  .pageContainer {
    min-height: 550px;
  }
}
@media (max-width: 55px) {
  .pageContainer {
    min-height: 85vh;
  }
}
