.print-form {
  padding: 2rem;
  margin: 2rem;
  min-height: 400px;
  /* border: 1px solid var(--green); */
  border: 1px solid var(--grey);
}
.print-form .print-title {
  font-size: 2rem;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.field-value {
  font-weight: 700;
  font-size: 1rem;
}
.print-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.print-footer .stamp {
  width: 120px;
  height: 160px;
  border: 2px solid #000;
  text-align: center;
  /* vertical-align: middle; */
  /* line-height: 10px; */
  /* margin: auto au; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.print-footer .assignment {
  font-weight: 600;
  font-size: 1.5rem;
}
.buttons-container {
  display: flex;
  justify-content: center;
  /* margin: 1rem; */
}
.buttons-container button {
  /* color: #2b7239; */
  color: white;
  text-align: center;
  font-family: Cairo;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 188.9% /* 34.002px */;
  padding: 4rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  /* width: 22%; */
}
#submit-button {
  width: 25%;
}
.customSingleCheckbox {
  /* margin-top: -0.7rem; */
  margin-top: -0.45rem;
  margin-left: 0rem !important;
}
@media (max-width: 1200px) {
  #submit-button {
    width: 30%;
  }
}
.phone-number {
  direction: ltr !important;
  text-align: end !important;
}
.text-green {
  color: var(--green);
}
