.CheckboxContainer {
  margin: 1rem 0;
  border-radius: 12px;
  background: rgb(237, 239, 240) !important;
  min-width: 20em !important;
}

.checked {
  background: linear-gradient(
    90deg,
    var(--first-linear-green) 0%,
    var(--second-linear-green) 96.59%
  ) !important;
  /* background: linear-gradient(90deg, #67d57d 0%, #68d567 96.59%) !important; */
}
