.loginButton {
  border-radius: 15px !important;
  background: linear-gradient(
    180deg,
    var(--first-linear-green) 0%,
    var(--second-linear-green) 100%
  ) !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
}

.logoutButton {
  border-radius: 15px !important;
  border-color: var(--second-linear-green) !important ;
  color: var(--second-linear-green) !important;
  margin-left: 0 !important;
}

/* .inputField {
  border-radius: 12px;
  padding: 0 0.2rem;
} */
.inputField div {
  border: none !important;
  border-radius: 15px;
}
.inputField div:hover {
  border: none !important;
  border-radius: 15px;
}
.inputField input {
  border-radius: 15px;
  background: #f8f9fa;
  padding: 10.5px 14px;
}
.errorMessgae {
  color: red !important;
  border-radius: 15px !important;
  border: 1px solid red;
  padding: 1rem;
}

.inputFiledError input {
  border: 1px solid red !important;
}
