.cardContainer {
  position: relative;
  transform-style: preserve-3d;
  border-radius: 50px;
  background: #fff;
  box-shadow: 0px 36px 25px -28px rgba(0, 0, 0, 0.13);
  margin: 0 0.3rem;
  transition: 0.5s ease;
  animation: dropdownback 0.5s;
  display: flex;
}

.cardContainer .face {
  position: relative;
  padding: 2.5rem 0 1rem 0;
  flex: 1;
}

.card3Image {
  position: absolute;
  top: -32px;
  background-color: #f8f9fa;
  border-radius: 50%;
  padding: 0.5em;
  overflow: hidden;
}
.card3Image img {
  border-radius: 50%;
  background-color: transparent;
  object-fit: contain;
  overflow: hidden;
}

.back {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* justify-content: start; */
  /* align-items: center; */
  transition: 0.5s all ease;
  /* visibility: hidden; */
  opacity: 1;
  /* transition: visibility 0s, opacity 0.5s linear; */
}

/* .cardContainer:hover {
  animation: dropdown 0.5s;
  animation-fill-mode: forwards;
} */

@keyframes dropdown {
  0% {
    min-height: 0;
  }
  100% {
    min-height: 270px;
  }
}

@keyframes dropdownback {
  0% {
    min-height: 250px;
  }
  100% {
    min-height: 0;
  }
}
/* .cardContainer:hover .back {
  visibility: visible;
  opacity: 1;
} */

.cursorEffect {
  cursor: pointer;
}

.cursorEffect:hover {
  color: #006838;
}
