.serviceTitle {
  color: #000;
  text-align: center;
  font-family: Cairo;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.AccordionTitle {
  color: #004496;
  text-align: center;
  font-family: Cairo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0;
}

.serviceDetailsBox {
  height: 210px;
  border-radius: 10px;
  background: linear-gradient(180deg, #f5faff 0%, #e2eefa 100%);
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 1rem;
}
.serviceAttachmentsDetailsBox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 1rem;
}
.DetailsTitle {
  margin-top: 1rem;
  color: #004496;
  text-align: center;
  font-family: Cairo;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 192.9%; /* 34.722px */
}

.DetailsSubTitle {
  color: #004496;
  text-align: center;
  font-family: Cairo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 169.4%; /* 27.104px */
  width: 90%;
}
.attachmentsContainer {
  border-radius: 10px;
  background: linear-gradient(180deg, #f5faff 0%, #e2eefa 100%);
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.detailsContainer:hover {
  box-shadow: 2px 2px 25px -10px rgba(0, 0, 0, 0.3) !important;
}

@media screen and (max-width: 900px) {
  .detailsContainerFlexBox {
    justify-content: center !important;
  }
}
@media (max-width: 768px) {
  .serviceTitle {
    font-size: 27px;
    margin-top: 5rem;
  }
}
@media (max-width: 560px) {
  .AccordionTitle {
    text-align: start;
  }
}
