.fieldName {
  color: #000 !important;
  /* text-align: center !important; */
  font-family: Cairo !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 188.9% !important; /* 34.002px */
}

.fieldValue {
  color: var(--second-linear-green) !important;
  /* text-align: center !important; */
  font-family: Cairo !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 188.9% !important; /* 34.002px */
}

.fieldStyle {
  padding: 0.5rem !important;
  /* border-radius: 15px !important;
  background: rgb(237, 239, 240) !important; */
}
.title {
  color: #000 !important;
  text-align: center !important;
  font-family: Cairo !important;
  font-size: 25px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}
