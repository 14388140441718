.bg {
  background: #f8f9fa;
}
.sec2bg {
  background-image: url(../../assets/images/bg/lebanon-left.png);
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  /* min-height: 115vh; */
  min-height: 800px;
}
.titleBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.youtubeIframe * {
  border-radius: 5px;
}
.others {
  /* margin-top: 1rem;
  top: 2rem;
  margin-bottom: 1rem;
  margin-right: 3rem; */
  font-size: 1.2rem;
}
.DialogButton {
  display: inline-block;
  color: #000;
  text-decoration: none;
  position: relative;
  overflow: visible;
  color: var(--green);
  cursor: pointer;
}

.DialogButton::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
  background-color: var(--green); /* Replace with your desired border color */
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.DialogButton:hover::after {
  transform: scaleX(1); /* Expand the border on hover */
}
.DialogButton:hover {
  color: var(--green);
  font-weight: 800;
}
@media (max-width: 1024px) {
  .sec2bg {
    min-height: 800px;
  }
}
@media (max-width: 768px) {
  .sec2bg {
    min-height: 1400px;
  }
}
@media (max-width: 550px) {
  .sec2bg {
    min-height: 2625px;
  }
}
