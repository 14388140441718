.navbar {
  box-shadow: 0px 19px 8px rgba(248, 249, 250, 1.2) !important;
  background-color: #ebf7fe !important;
  color: #ebf7fe !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  z-index: 222;
}

.navbar .navbar-brand img {
  width: 82%;
}

.custom-toggle-button {
  background: linear-gradient(
    180deg,
    var(--first-linear-green) 0%,
    var(--second-linear-green) 100%
  );
  border: none !important;
  margin-top: 1.5em;
  transition: transform 0.3s ease;
}

.custom-toggle-button.collapsed {
  background: linear-gradient(
    180deg,
    var(--first-linear-green) 0%,
    var(--second-linear-green) 100%
  );
}

.custom-toggle-button:not(.collapsed) {
  background-color: rgb(192, 233, 192) !important;
}

.navbar .navbar-nav .nav-link a {
  color: #000;
  text-decoration: none;
  /* padding-top: 2rem; */
  position: relative;
  overflow: hidden;
}

.navbar-nav .nav-link a::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--green); /* Replace with your desired color */
  transform: scaleX(0);
  transition: transform 0.3s cubic-bezier(0.2, 0.8, 0.2, 1); /* Add transition property for smooth animation */
}

.navbar-nav .nav-link a:hover::before {
  transform: scaleX(1); /* Expand the line on hover */
}
.navbar .navbar-nav .nav-link a:hover,
.navbar .navbar-nav .nav-link p:hover {
  color: var(--green);
  text-decoration: none;
  font-weight: 800;
}
#login-btn,
.logoutButton,
#dashboard-btn {
  background: linear-gradient(
    180deg,
    var(--first-linear-green) 0%,
    var(--second-linear-green) 100%
  ) !important;
  border: none !important;
  width: fit-content !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-radius: 26px !important;
  margin-right: 0.5rem !important;
}
#login-btn a,
.logoutButton,
#dashboard-btn a {
  color: #f5f5f5 !important;
  font-size: 0.9rem;
  font-weight: bold;
  color: #fff !important;
}
#login-btn:hover,
.logoutButton:hover,
#dashboard-btn:hover {
  background-color: #edf7ff !important;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.3) !important;
  /* border: 1px solid #006838 !important; */
}
/* #login-btn:hover a,
#dashboard-btn:hover a,
.logoutButton:hover {
  color: var(--green) !important;
} */
#login-btn:hover a::before,
#dashboard-btn:hover a::before {
  width: 0 !important;
}

@media (max-width: 768px) {
  .navbar {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .navbar {
    position: absolute !important;
    width: 100%;
    z-index: 5555;
  }

  .navbar-collapse {
    padding-bottom: 2rem;
  }

  .navbar .navbar-nav .nav-link a {
    padding-right: 1rem;
  }
  #login-btn,
  #dashboard-btn {
    padding-right: 0rem;
    padding-left: 0rem;
    width: 16%;
  }

  #login-btn a,
  #dashboard-btn a {
    padding: 0 !important;
  }
}
@media (max-width: 550px) {
  #login-btn,
  #dashboard-btn {
    padding-right: 0rem;
    padding-left: 0rem;
    width: 35%;
  }
}
@media (max-width: 374px) {
  .navbar-brand {
    width: 60% !important;
  }
  .navbar .navbar-brand img {
    width: 100% !important;
  }
  #login-btn,
  #dashboard-btn {
    width: 43%;
    /* text-align: center; */
  }
}
