.imgContainer {
  position: relative;
  color: #fff;
}
.loginBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.greenColor {
  /* color: #439e56; */
  color: var(--green);
}

.marginTopZero {
  margin-top: 0 !important;
}
.loginButton {
  border-radius: 15px !important;
  width: 11rem !important;
  background: linear-gradient(
    180deg,
    var(--first-linear-green) 0%,
    var(--second-linear-green) 100%
  ) !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 1.2rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
}

.welcomeText {
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: Cairo;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 70%;
  z-index: 2;
}

.loginText {
  color: #000 !important;
  text-align: center !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.loginSubText {
  color: #000 !important;
  text-align: center !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 188.9% !important;
  margin-top: 0rem !important;
}

.registerText {
  /* color: #439e56 !important; */
  color: var(--green) !important;
  text-align: center !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 161.9% !important; /* 25.904px */
  text-decoration: none !important;
  width: fit-content !important;
  margin: 2rem auto;
}
.inputField div {
  border: none !important;
  border-radius: 15px;
}
.inputField div:hover {
  border: none !important;
  border-radius: 15px;
}
.inputField input {
  border-radius: 15px;
  background: #f8f9fa;
  padding: 10.5px 14px;
}
.errorClass {
  color: red !important;
}

.forgetPassword {
  display: block;
  width: fit-content;
  text-decoration: none;
}

.errorMessgae {
  color: red !important;
  border-radius: 15px !important;
  border: 1px solid red;
  padding: 1rem;
}

.inputFiledError input {
  border: 1px solid red !important;
}

p {
  margin-bottom: 0 !important;
}
